import { LegacyRef, useCallback } from 'react';
import { ReactDatePickerCustomHeaderProps, ReactDatePickerProps, registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { CalendarBase } from './calendar-base.component';
import CalendarHeader from './calendar-header.component';

import en from 'date-fns/locale/en-US';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarInput, { CalendarInputProps } from './calendar-input.component';
import { CoreInputProps } from '../../core-input';

registerLocale('en-us', en);

export interface CoreCalendarProps<WithRange extends boolean | undefined = undefined>
	extends ReactDatePickerProps<never, WithRange> {
	inputProps?: CalendarInputProps;
	showFilterIcon?: boolean;
}

const CoreCalendar = <WithRange extends boolean | undefined = undefined>(
	props: CoreCalendarProps<WithRange> & { onClear?: () => void },
	ref: LegacyRef<HTMLInputElement> | undefined
) => {
	const { inputProps, dateFormat = 'MM/dd/yyyy', onClear, showFilterIcon, ...calendarProps } = props;
	const { i18n } = useTranslation();

	const renderMonthCustomHeader = useCallback((props: ReactDatePickerCustomHeaderProps) => {
		const { date, nextMonthButtonDisabled, prevMonthButtonDisabled, increaseMonth, decreaseMonth } = props;

		return (
			<CalendarHeader
				date={date}
				dateFormat="LLLL yyyy"
				prevUnitButtonDisabled={prevMonthButtonDisabled}
				nextUnitButtonDisabled={nextMonthButtonDisabled}
				decreaseUnit={decreaseMonth}
				increaseUnit={increaseMonth}
			/>
		);
	}, []);

	const renderYearCustomHeader = useCallback((props: ReactDatePickerCustomHeaderProps) => {
		const { date, prevYearButtonDisabled, nextYearButtonDisabled, increaseYear, decreaseYear } = props;

		return (
			<CalendarHeader
				date={date}
				dateFormat="yyyy"
				prevUnitButtonDisabled={prevYearButtonDisabled}
				nextUnitButtonDisabled={nextYearButtonDisabled}
				decreaseUnit={decreaseYear}
				increaseUnit={increaseYear}
			/>
		);
	}, []);

	return (
		<CalendarBase
			dateFormat={dateFormat}
			showPopperArrow={false}
			selectsRange
			locale="en-us"
			renderCustomHeader={props.showMonthYearPicker ? renderYearCustomHeader : renderMonthCustomHeader}
			timeInputLabel=""
			customInput={<CalendarInput {...inputProps} ref={ref} onClear={onClear} showFilterIcon={showFilterIcon} />}
			{...calendarProps}
		/>
	);
};

export default CoreCalendar;
