import {
	EngagementDto,
	PageDto,
	SourceFilter,
	PageOptionsDto,
	LanguageFilter,
	ProvidersFilter,
	ON_SURVEY_TRACKING_EVENT,
	ON_SURVEY_EVENT,
	ON_SURVEY_ANSWER_EVENT,
	JOIN_SURVEY_EVENTS_ROOM,
} from '@families-link/shared';
import api from '.';
import { AnyAaaaRecord } from 'dns';
import { configService } from '../../modules/_core';
import { io } from 'socket.io-client';
import { useAppSelector, RootState } from '..';
import { getLocalStorageDistrictId } from '../../modules/_core/utils';

const engagementsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getEngagementsList: builder.query<
			PageDto<EngagementDto>,
			PageOptionsDto &
				SourceFilter &
				LanguageFilter &
				ProvidersFilter & { district?: string | null; start?: string | null; end?: string | null; archived?: boolean }
		>({
			query: ({ page, take, orderField, order, source, languages, providers, district, start, end, archived }) => ({
				url: `engagements`,
				params: { page, take, orderField, order, source, languages, providers, district, start, end, archived },
			}),
			providesTags: ['Engagement', 'Survey', 'SurveyAnswer'],
			async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState, dispatch }) {
				const state = getState() as RootState;
				const token = state.session.access?.token;

				if (!token) {
					console.error('No access token found');

					return;
				}

				const socket = io(configService.get('NX_PUBLIC_APP_API_URL'), {
					auth: { token },
					transports: ['websocket', 'polling'],
				});

				socket.on('connect', () => {
					console.log('WebSocket connected!');
				});

				const districtId = getLocalStorageDistrictId();

				socket.emit(JOIN_SURVEY_EVENTS_ROOM, { districtId });

				const eventListner = (data: any) => {
					dispatch(engagementsApi.util.invalidateTags([{ type: 'Survey' }]));
				};

				socket.on(ON_SURVEY_EVENT, eventListner);
				socket.on(ON_SURVEY_TRACKING_EVENT, eventListner);
				socket.on(ON_SURVEY_ANSWER_EVENT, eventListner);

				try {
					await cacheEntryRemoved;
				} finally {
					socket.off(ON_SURVEY_EVENT, eventListner);
					socket.off(ON_SURVEY_TRACKING_EVENT, eventListner);
					socket.off(ON_SURVEY_ANSWER_EVENT, eventListner);
					socket.disconnect();
				}
			},
		}),
		getEngagement: builder.query<EngagementDto, { id?: string | null }>({
			query: ({ id }) => ({
				url: `engagements/${id}`,
			}),
		}),
		createEngagement: builder.mutation<EngagementDto, FormData>({
			query: (data) => ({
				url: `engagements`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: ['Engagement'],
		}),
		updateEngagement: builder.mutation<EngagementDto, { id?: string; data: FormData }>({
			query: ({ id, data }) => ({
				url: `engagements/${id}`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: ['Engagement'],
		}),
		deleteEngagement: builder.mutation<EngagementDto, { id: string }>({
			query: ({ id }) => ({
				url: `engagements/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Engagement'],
		}),
		archiveEngagement: builder.mutation<EngagementDto, { id: string }>({
			query: ({ id }) => ({
				url: `engagements/${id}/archive`,
				method: 'PUT',
			}),
			invalidatesTags: ['Engagement'],
		}),
		unarchiveEngagement: builder.mutation<EngagementDto, { id: string }>({
			query: ({ id }) => ({
				url: `engagements/${id}/unarchive`,
				method: 'PUT',
			}),
			invalidatesTags: ['Engagement'],
		}),
		getEngagementsByFamilyId: builder.query<
			PageDto<EngagementDto>,
			PageOptionsDto & {
				familyId?: string;
			}
		>({
			query: ({ familyId, page, take, orderField, order }) => ({
				url: `engagements/family/${familyId}`,
				params: {
					page,
					take,
					orderField,
					order,
				},
			}),
			providesTags: ['Engagement'],
		}),
		getEngagementsByProviderId: builder.query<
			PageDto<EngagementDto>,
			PageOptionsDto & {
				providerId?: string;
			}
		>({
			query: ({ providerId, page, take, orderField, order }) => ({
				url: `engagements/provider/${providerId}`,
				params: {
					page,
					take,
					orderField,
					order,
				},
			}),
			providesTags: ['Engagement'],
		}),
		exportEngagementsToCSV: builder.mutation<
			{ fileUrl: string },
			SourceFilter &
				LanguageFilter &
				ProvidersFilter & { district?: string; start?: string | null; end?: string | null; fields?: string }
		>({
			query: ({ source, languages, providers, district, start, end, fields }) => ({
				url: `engagements/export/csv`,
				params: { source, languages, providers, district, start, end, fields },
				responseType: 'json',
			}),
		}),
	}),
});

export default engagementsApi;

export const {
	useGetEngagementsListQuery,
	useGetEngagementQuery,
	useCreateEngagementMutation,
	useUpdateEngagementMutation,
	useDeleteEngagementMutation,
	useGetEngagementsByFamilyIdQuery,
	useGetEngagementsByProviderIdQuery,
	useExportEngagementsToCSVMutation,
	useArchiveEngagementMutation,
	useUnarchiveEngagementMutation,
	useLazyGetEngagementsListQuery,
} = engagementsApi;
