import { FC } from 'react';
import { UserRolesEnum } from '@families-link/shared';
import { useInitiateState } from '../../../_core/hooks';
import { getLocalStorageFamilyId } from '../../../_core/utils';
import { useGetFamilyQuery, useUpdateFamilyMutation } from '../../../../store/api/families.api';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { useCurrentAccessTokenSelector } from '../../../auth';
import { Avatar, Box, Container, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { useOpenModalForm } from '../../hooks/modal-forms/use-open-modal-form.hook';
import { getObjectLanguage, LanguageOptions } from '../../../_core/components/languages-component';
import { EditIcon, AvatarIcon } from '../../../_core/constants/icons.constants';

interface FamilyDetailsProps {
	familyId?: string;
	isEngagementPage?: boolean;
}

export const FamilyDetails: FC<FamilyDetailsProps> = ({ familyId: propFamilyId, isEngagementPage = false }) => {
	useInitiateState('family');
	const familyId = propFamilyId || getLocalStorageFamilyId();

	const currentToken = useCurrentAccessTokenSelector();
	const isRequester = currentToken?.payload.sur === UserRolesEnum.Requester;

	const { data: family, error, isLoading, refetch } = useGetFamilyQuery(familyId ? { id: familyId } : skipToken);
	const [t] = useTranslation();

	const languageCode = family?.preferedLanguage;
	const option = getObjectLanguage(languageCode);

	const [updateFamily] = useUpdateFamilyMutation();
	const featchActionEdit = async (data: FormData) => {
		await updateFamily({ id: data.get('_id')?.toString(), data }).unwrap();
		refetch();
	};

	const handleOpenModalEdit = useOpenModalForm({
		featchAction: featchActionEdit,
		isCreate: false,
		submitButtonName: t('families.forms.buttons.save'),
		toastText: 'Family contact was successfully updated',
	});

	return (
		<Container
			maxWidth={false}
			sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px' }}
		>
			{isEngagementPage ? null : (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'flex-start',
						width: '100%',
						mt: 1,
					}}
				>
					<Box>
						<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
							{t('families.family-contact')}
						</Typography>
					</Box>

					<Box>
						<IconButton color="primary" disabled={isRequester} onClick={() => handleOpenModalEdit(family)}>
							<EditIcon />
						</IconButton>
					</Box>
				</Box>
			)}
			<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '100%' }}>
				<Box
					sx={{
						flexGrow: 1,
						padding: 1,
						display: 'flex',
						flexDirection: 'row',
						gap: 3,
					}}
				>
					{family?.imageUrl ? (
						<Avatar
							src={family?.imageUrl}
							alt={family?.fullName}
							style={{ width: 98, height: 98, borderRadius: '32px' }}
						/>
					) : (
						<Box
							style={{
								width: 98,
								height: 98,
								borderRadius: '32px',
								backgroundColor: 'var(--blue-8-opacity, rgba(36, 57, 106, 0.08))',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<AvatarIcon />
						</Box>
					)}

					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
						}}
					>
						<Box>
							<Typography variant="body2" sx={{ fontWeight: 'regular', color: (theme) => theme.colors.grayBlue }}>
								{t('families.columns.full-name')}
							</Typography>
							<Typography sx={{ fontWeight: 'bold' }}>{family?.fullName}</Typography>{' '}
						</Box>
						<Box>
							{' '}
							<Typography variant="body2" sx={{ fontWeight: 'regular', color: (theme) => theme.colors.grayBlue }}>
								{t('families.columns.location')}
							</Typography>
							<Typography sx={{ fontWeight: 'bold' }}>{family?.location}</Typography>
						</Box>
					</Box>
				</Box>

				<Box
					sx={{
						flexGrow: 1,
						padding: 1,
						display: 'flex',
						height: '100%',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							height: '100%',
						}}
					>
						<Box>
							<Typography variant="body2" sx={{ fontWeight: 'regular', color: (theme) => theme.colors.grayBlue }}>
								{t('families.columns.email')}
							</Typography>
							<Typography sx={{ fontWeight: 'bold' }}>{family?.email}</Typography>
						</Box>
						{isEngagementPage ? (
							// <Box>
							// 	{' '}
							// 	<Typography variant="body2" sx={{ fontWeight: 'regular', color: (theme) => theme.colors.grayBlue }}>
							// 		{t('families.columns.prefered-language')}
							// 	</Typography>
							// 	<Box sx={{ fontWeight: 'bold' }}>{option ? option.label : null}</Box>
							// </Box>
							<Box>
								<Typography variant="body2" sx={{ fontWeight: 'regular', color: (theme) => theme.colors.grayBlue }}>
									{t('families.columns.phone-number')}
								</Typography>
								<Typography sx={{ fontWeight: 'bold' }}>{family?.phoneNumber}</Typography>
							</Box>
						) : (
							<Box>
								<Typography variant="body2" sx={{ fontWeight: 'regular', color: (theme) => theme.colors.grayBlue }}>
									{t('families.columns.phone-number')}
								</Typography>
								<Typography sx={{ fontWeight: 'bold' }}>{family?.phoneNumber}</Typography>
							</Box>
						)}
					</Box>
				</Box>
				<Box
					sx={{
						flexGrow: 1,
						padding: 1,
						display: 'flex',
						height: '100%',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
						}}
					>
						{!isEngagementPage && (
							<Box>
								<Typography variant="body2" sx={{ fontWeight: 'regular', color: (theme) => theme.colors.grayBlue }}>
									{t('families.columns.prefered-language')}
								</Typography>
								<Box sx={{ fontWeight: 'bold' }}>{option ? option.label : null}</Box>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
		</Container>
	);
};
