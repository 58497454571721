import { FC, useEffect } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FieldError, FieldValues, FormProvider, Resolver, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
	useWriteSurveyAnswersMutation,
	useLazyGetFamilySurveyTrackQuery,
	useLazyGetSurveyTemplateBySurveyIdQuery,
	useLazyCheckExistSurveyAnswerQuery,
} from '../../../../store/api/survey.api';
import { SurveyQuestionRender } from '../../components/question/question.component';
import SurveyHeader from '../../../_core/components/header-survey/header-survey.component';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { SurveyQuestionInterface } from '@families-link/shared';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';

const customResolver: Resolver = async (values: FieldValues, context: { surveyTemplate: any }) => {
	const errors: { [key: string]: FieldError } = {};

	if (context.surveyTemplate) {
		context.surveyTemplate.questions.forEach((question: SurveyQuestionInterface) => {
			const fieldName = `field_${question._id}`;

			if (question.isRequired) {
				const fieldValue = values[fieldName];

				if (!fieldValue || (Array.isArray(fieldValue) && fieldValue.length === 0)) {
					errors[fieldName] = {
						type: 'required',
						message: `This field is required`,
					};
				}
			}
		});
	}

	return {
		values: values,
		errors: errors,
	};
};

export const SurveyViewPage: FC = () => {
	const [t, i18n] = useTranslation();
	const [triggerGetFamilySurveyTrackQuery, { data: familyTrack }] = useLazyGetFamilySurveyTrackQuery();
	const [triggerGetSurveyTemplateBySurveyIdQuery, { data: surveyTemplate }] = useLazyGetSurveyTemplateBySurveyIdQuery();
	const [triggerCheckExistSurveyAnswerQuery, { data: surveyAnswers }] = useLazyCheckExistSurveyAnswerQuery();
	const [writeSurveyAnswers] = useWriteSurveyAnswersMutation();

	const { urlHash, familyId: familyIdParam, surveyId: surveyIdParam } = useParams();

	useEffect(() => {
		if (urlHash) {
			triggerGetFamilySurveyTrackQuery(urlHash);
		}
	}, [urlHash]);
	const familyId = familyTrack?.familyId ?? familyIdParam ?? '';
	const surveyId = familyTrack?.engagementSurveyId ?? surveyIdParam ?? '';

	useEffect(() => {
		if (surveyId && familyId) {
			triggerGetSurveyTemplateBySurveyIdQuery(surveyId);
			triggerCheckExistSurveyAnswerQuery({
				surveyId,
				familyId,
			});
		}
	}, [surveyId, familyId]);

	const defaultValues = {
		familyId,
		surveyId,
		fullName: '',
		...surveyTemplate?.questions?.reduce((result: any, el: SurveyQuestionInterface) => {
			result[`field_${el._id}`] = '';

			return result;
		}, {}),
	};

	const methods = useForm({
		defaultValues,
		resolver: (values, context, fieldNames) => customResolver(values, { surveyTemplate }, fieldNames),
		mode: 'onSubmit',
	});

	const {
		register,
		handleSubmit,
		formState: { isDirty, isLoading, isSubmitting, isSubmitted, isValid, errors },
	} = methods;

	const disableSubmit = !isValid && (isDirty || isSubmitted);
	const loadingSubmit = isLoading || isSubmitting;

	const browserLanguage = navigator.language;
	const isArabic = surveyTemplate?.language === 'Arabic' || browserLanguage === 'ar';

	useEffect(() => {
		const browserLanguage = navigator.language;

		const isArabicLanguage = surveyTemplate?.language === 'Arabic';
		const isSpanishLanguage = surveyTemplate?.language === 'Spanish' || surveyTemplate?.language === 'Spanish (Spain)';

		const selectedLanguage = isArabicLanguage
			? 'ar'
			: isSpanishLanguage
			? 'es'
			: browserLanguage === 'ar'
			? 'ar'
			: browserLanguage === 'es'
			? 'es'
			: 'en';

		i18n.changeLanguage(selectedLanguage);
	}, [i18n, surveyTemplate?.language]);

	const onSubmit = async (values: any) => {
		const transformValues = Object.keys(values).reduce(
			(submitObject: any, key: string) => {
				if (key.startsWith('field_')) {
					const fieldId = key.replace('field_', '');
					submitObject['answers'].push({
						fieldId,
						value: values[key],
					});
				} else {
					submitObject[key] = values[key];
				}
				submitObject['familyId'] = familyId;
				submitObject['surveyId'] = surveyId;

				return submitObject;
			},
			{ answers: [] }
		);
		await writeSurveyAnswers(transformValues);
	};

	if (!familyId || !surveyId) {
		return null;
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flex: 1,
				justifyContent: 'center',
				minHeight: 0,
				height: '100%',
				direction: isArabic ? 'rtl' : 'ltr',
				textAlign: isArabic ? 'right' : 'left',
			}}
		>
			{surveyTemplate ? (
				<Box sx={{ width: '100%', height: '100%' }}>
					<Box component="header" sx={{ mb: 1 }}>
						<SurveyHeader title={surveyTemplate?.title ?? 'Survey'} />
					</Box>
					{surveyAnswers ? (
						<Paper elevation={0} sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
							<Typography fontSize={14} sx={{ fontWeight: '500' }}>
								{'Your response has been recorded'}
							</Typography>
						</Paper>
					) : (
						<FormProvider {...methods}>
							<Box
								component="form"
								onSubmit={handleSubmit(onSubmit)}
								sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
							>
								<Paper elevation={0} sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
									<Typography fontSize={14} sx={{ fontWeight: '500', mb: '10px' }}>
										{t('survey-templates.forms.placeholders.survey-name')}
										<span style={{ color: 'red', marginLeft: '4px', fontWeight: '300' }}>*</span>
									</Typography>
									<CoreTextField
										requiredMark
										fullWidth
										placeholder={t('survey-templates.forms.placeholders.survey-name') ?? ''}
										{...register('fullName')}
									/>
								</Paper>

								{surveyTemplate?.questions?.map((question, index) => {
									const fieldName = `field_${question._id}`;
									const error = errors[fieldName];

									return (
										<Paper
											key={question._id.toString()}
											elevation={0}
											sx={{ p: 3, display: 'flex', flexDirection: 'column' }}
										>
											<Typography fontSize={14} sx={{ fontWeight: '500', mb: '10px' }}>
												{index + 1}. {question.text}{' '}
												{question.isRequired && (
													<span style={{ color: 'red', marginLeft: '4px', fontWeight: '300' }}>*</span>
												)}
											</Typography>
											<SurveyQuestionRender question={question} />
											{error && (
												<Typography color="error" fontSize="12px" sx={{ mt: 1 }}>
													The answer is required
												</Typography>
											)}
										</Paper>
									);
								})}
								<Box>
									<CoreButton type="submit" contraMode disabled={disableSubmit} loading={loadingSubmit}>
										{'Submit'}
									</CoreButton>
								</Box>
							</Box>
						</FormProvider>
					)}
				</Box>
			) : null}
		</Box>
	);
};
