import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import {
	useInviteRequestMutation,
	useRegisterUserMutation,
	useValidateRegistrationLinkQuery,
} from '../../../../store/api/auth.api';
import { containerStyles, googleAuthRowStyles, wrapperStyles } from './signup-with-email.styles';
import { useLangParam } from '../../hooks/use-lang-param.hook';
import { SignUpWithEmailForm } from '../../components/signup-form';

import { UserInfoDto, UserRolesEnum } from '@families-link/shared';
import { getErrorStatusCode, getFieldFromConflictError } from '../../../_core/utils/error.utils';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { CodeConfirmationForm } from '../../components/code-confirmation-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { endTextStyles } from '../restore-password/restore-password.styles';
import { Trans } from 'react-i18next';
import jwtDecode from 'jwt-decode';
import { JwtPayloadWithRole } from '../../interfaces/token-with-payload.interface';
import { RootRoutes } from '../../../../constants';

interface FormsState {
	profile: UserInfoDto | null;
	activeForm: 'registration' | 'end';
}

export const SignUpWithEmailPage: FC = () => {
	useLangParam();

	const navigate = useNavigate();

	const [error, setError] = useState<FetchBaseQueryError | SerializedError | undefined>();
	const [registerUser] = useRegisterUserMutation();

	const [inviteRequest, { reset: resetInvitation }] = useInviteRequestMutation();

	const [{ activeForm }, setFormState] = useState<FormsState>({
		activeForm: 'registration',
		profile: null,
	});

	const [searchParams] = useSearchParams();

	const { token, tokenPayload } = useMemo(() => {
		const token = searchParams.get('token') ?? '';
		const tokenPayload = jwtDecode<JwtPayloadWithRole>(token);

		return { token, tokenPayload };
	}, [searchParams]);

	const { data: ValidLinkData } = useValidateRegistrationLinkQuery(
		{ token },
		{
			skip: !token,
		}
	);

	const isValidLink = ValidLinkData?.isValid ?? !error;

	useEffect(() => {
		if (!isValidLink) {
			navigate(RootRoutes.NotFound);
		}
	}, [isValidLink, navigate]);

	const districtId = tokenPayload?.dis;

	const handleRegisterFormSubmit = useCallback(
		async (data: UserInfoDto) => {
			try {
				const user = {
					...data,
					role: UserRolesEnum.Requester,
					active: false,
					districtId,
				};

				await registerUser(user).unwrap();
				await inviteRequest({ email: data.email }).unwrap();

				setFormState({
					activeForm: 'end',
					profile: data,
				});
			} catch (err) {
				setError(err as FetchBaseQueryError | SerializedError);
			}
		},
		[registerUser, inviteRequest]
	);

	if (!token) {
		return null;
	}
	return (
		<Container sx={containerStyles}>
			<Box sx={wrapperStyles}>
				{activeForm === 'registration' && (
					<>
						<SignUpWithEmailForm profile={{ name: '', email: '' }} onSubmit={handleRegisterFormSubmit} error={error} />
					</>
				)}
				{activeForm === 'end' && (
					<Typography sx={endTextStyles}>
						<Trans i18nKey="sign-up.confirm-email" />
					</Typography>
				)}
			</Box>
		</Container>
	);
};

export default SignUpWithEmailPage;
