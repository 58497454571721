import React, { useCallback, useEffect, useState } from 'react';
import { FieldError, FieldValues, ResolverOptions, useForm } from 'react-hook-form';
import { Box, MenuItem, Grid, Typography, TextField } from '@mui/material';
import {
	LanguageEnum,
	ProviderDto,
	ProviderInfoDto,
	WorkStatusEnum,
	ServiceTypeStatusEnum,
} from '@families-link/shared';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { useTranslation } from 'react-i18next';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import { CoreSelect } from '../../../_core/components/_ui/core-select';

import { Verified, InHouse, Outsource, InternationalFlag } from '../../../_core/constants/icons.constants';
import UploadPhoto from '../../../_core/components/upload-photo/upload-photo.component';
import { getObjectLanguage } from '../../../_core/components/languages-component';
import { AutocompleteTextField, CoreAutoComplete } from '../../../_core/components/_ui/core-autocomplete';
import { CoreModal, CoreModalProps } from '../../../_core/components/_ui/core-modal';
import { mergeSx } from 'merge-sx';
import { buttonModalWindowStyles, mainTitleModalWindowStyles } from '../../../_core/styles';

export type AddProviderFormProps = CoreModalProps & {
	resolver?: <TFieldValues extends FieldValues, TContext>(
		values: TFieldValues,
		context: TContext | undefined,
		options: ResolverOptions<TFieldValues>
	) => any;
	formError?: any;
	defaultValues: any;
	submitAction(formData: FormData): Promise<void>;
	onClose: () => void;
};

export const AddProviderForm: React.FC<AddProviderFormProps> = ({
	onClose,
	submitAction,
	formError,
	resolver,
	defaultValues,
	...modalProps
}) => {
	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		reset,
		setValue,
		setError,
		clearErrors,
		control,
		formState: { errors, isDirty, isLoading, isSubmitting, isSubmitted, isValid },
	} = useForm({
		resolver,
		defaultValues,
		mode: 'all',
	});

	useEffect(() => {
		if (errors['targetLanguage']) {
			setError('targetLanguage', {
				type: 'manual',
				message: 'Target language is required',
			});
		}
	}, [setError, errors['targetLanguage']]);

	useEffect(() => {
		reset(defaultValues);
	}, [defaultValues, reset]);

	const disableSubmit = !isValid && (isDirty || isSubmitted);
	const loadingSubmit = isLoading || isSubmitting;

	const [t] = useTranslation();
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const handleFormSubmit = useCallback(
		async (values: FieldValues) => {
			const providerValues = values as ProviderInfoDto | ProviderDto;
			const formData = new FormData();

			Object.keys(providerValues).forEach((key) => {
				formData.append(key, (providerValues as any)[key]);
			});

			if (selectedFile) {
				formData.append('file', selectedFile);
			}

			try {
				await submitAction(formData);
				onClose();
			} catch (error) {
				console.error(error);
			}
		},
		[submitAction, setError, selectedFile, onClose]
	);

	const handleFileChange = (file: File | null) => {
		if (file) {
			setSelectedFile(file);
		} else {
			setSelectedFile(null);
		}
	};

	return (
		<CoreModal onClose={onClose} {...modalProps} bodySx={{ pt: 0, px: 5 }} paperSx={{ width: '100%' }}>
			<Typography variant="h5" sx={mergeSx(mainTitleModalWindowStyles, { mb: 5 })}>
				{t('providers.create-provider')}
			</Typography>
			<Box
				component="form"
				onSubmit={handleSubmit(handleFormSubmit)}
				sx={{ display: 'flex', flexDirection: 'row', gap: '40px' }}
			>
				<Box sx={{ width: '236px', maxHeight: 364 }}>
					<UploadPhoto onFileChange={handleFileChange} file={undefined} />
				</Box>
				<Box>
					<Box sx={{ display: 'flex', flexDirection: 'column', width: '360px', gap: '24px', mb: '40px' }}>
						<CoreTextField
							label={t('providers.full-name')}
							{...register('fullName')}
							placeholder={t('providers.placeholders.full-name') ?? ''}
							requiredMark
							fullWidth
							error={!!errors['fullName']}
							helperText={errors['fullName'] ? (errors['fullName'] as FieldError).message : undefined}
						/>
						{formError && (
							<Typography>
								{formError?.data?.message ? formError.data.message : t('errors.invalid-credentials-error')}
							</Typography>
						)}

						<CoreAutoComplete
							label={t('providers.target-lang')}
							multiple={true}
							requiredMark
							fullWidth
							{...register('targetLanguage')}
							error={!!errors['targetLanguage']}
							helperText={errors['targetLanguage'] ? (errors['targetLanguage'] as FieldError).message : undefined}
							options={Object.values(LanguageEnum)}
							control={control}
							getOptionLabel={(option) => {
								const language = getObjectLanguage(option);
								return language ? language.name : '';
							}}
							selectOnFocus={true}
							isOptionEqualToValue={(option, value) => option === value}
							renderInput={(params) => (
								<AutocompleteTextField
									{...params}
									error={!!errors['targetLanguage']}
									placeholder={t('providers.placeholders.prefered-language') ?? ''}
									sx={{
										'& .MuiOutlinedInput-root': {
											'&.Mui-focused fieldset': {
												borderColor: (theme) => theme.colors.blue,
											},
										},
									}}
								/>
							)}
							renderOption={(props, option) => {
								const language = getObjectLanguage(option);
								return (
									<MenuItem {...props} key={language?.value}>
										{language?.name}
									</MenuItem>
								);
							}}
						/>

						<CoreSelect
							id="verificationType"
							label={t('providers.verification-type')}
							{...register('verificationType')}
							requiredMark
							placeholder={t('providers.placeholders.options') ?? ''}
							error={!!errors['verificationType']}
							helperText={errors['verificationType'] ? (errors['verificationType'] as FieldError).message : undefined}
							fullWidth
							onChange={(e) => {
								clearErrors('verificationType');
								setValue('verificationType', e.target.value);
								trigger('verificationType');
							}}
						>
							<MenuItem value="true">
								{t('providers.verified')} <Verified style={{ marginLeft: '8px' }} />{' '}
							</MenuItem>
							<MenuItem value="false">{t('providers.no-verified')}</MenuItem>
						</CoreSelect>

						<CoreSelect
							label={t('providers.work-status')}
							id="work-status"
							{...register('workStatus')}
							fullWidth
							requiredMark
							placeholder={t('providers.placeholders.options') ?? ''}
							error={!!errors['workStatus']}
							helperText={errors['workStatus'] ? (errors['workStatus'] as FieldError).message : undefined}
							onChange={(e) => {
								clearErrors('workStatus');
								setValue('workStatus', e.target.value);
								trigger('workStatus');
							}}
						>
							<MenuItem value={WorkStatusEnum.InHouse}>
								{t('providers.work-in-house')} <InHouse style={{ marginLeft: '8px' }} />
							</MenuItem>
							<MenuItem value={WorkStatusEnum.Outsource}>
								{t('providers.work-outsource')} <Outsource style={{ marginLeft: '8px' }} />
							</MenuItem>
						</CoreSelect>

						<CoreSelect
							label={'Service Type'}
							id="serviceType"
							{...register('serviceType')}
							fullWidth
							requiredMark
							placeholder={t('providers.placeholders.options') ?? ''}
							error={!!errors['serviceType']}
							helperText={errors['serviceType'] ? (errors['serviceType'] as FieldError).message : undefined}
							onChange={(e) => {
								clearErrors('serviceType');
								setValue('serviceType', e.target.value);
								trigger('serviceType');
							}}
						>
							<MenuItem value={ServiceTypeStatusEnum.Appointment}>Appointment</MenuItem>
							<MenuItem value={ServiceTypeStatusEnum.Project}>Project</MenuItem>
							<MenuItem value={ServiceTypeStatusEnum.Both}>Both</MenuItem>
						</CoreSelect>
					</Box>
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item>
							<CoreButton variant="secondary" contraMode onClick={onClose} sx={buttonModalWindowStyles}>
								{t('providers.close')}
							</CoreButton>
						</Grid>
						<Grid item>
							<CoreButton
								type="submit"
								contraMode
								loading={loadingSubmit}
								disabled={disableSubmit}
								sx={buttonModalWindowStyles}
							>
								{t('providers.create')}
							</CoreButton>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</CoreModal>
	);
};
