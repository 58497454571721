import { FC, memo } from 'react';
import { Box, Typography } from '@mui/material';

import { format } from 'date-fns';
import { CoreIconButton } from '../../core-button/core-icon-button.component';
import { ArrowRight, ArrowLeft } from '../../../../constants/icons.constants';

interface CalendarHeaderProps {
	prevUnitButtonDisabled: boolean;
	nextUnitButtonDisabled: boolean;
	date: Date;
	dateFormat: string;
	increaseUnit(): void;
	decreaseUnit(): void;
}

const CalendarHeader: FC<CalendarHeaderProps> = (props) => {
	const { prevUnitButtonDisabled, nextUnitButtonDisabled, decreaseUnit, increaseUnit, date, dateFormat } = props;

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				mb: 1.5,
				position: 'relative',
			}}
		>
			<CoreIconButton
				sx={{
					top: 0,
					left: 0,
					color: (theme) => theme.colors.gray,
					position: 'absolute',
				}}
				onClick={decreaseUnit}
				disabled={prevUnitButtonDisabled}
			>
				<ArrowLeft />
			</CoreIconButton>
			<CoreIconButton
				sx={{
					top: 0,
					right: 0,
					color: (theme) => theme.colors.gray,
					position: 'absolute',
				}}
				onClick={increaseUnit}
				disabled={nextUnitButtonDisabled}
			>
				<ArrowRight />
			</CoreIconButton>
			<Typography
				variant="body1"
				sx={{
					flex: 1,
					textAlign: 'center',
					fontWeight: 700,
					lineHeight: '36px',
					height: '36px',
					textTransform: 'capitalize',
				}}
			>
				{format(date, dateFormat)}
			</Typography>
		</Box>
	);
};

export default memo(CalendarHeader);
