import { FC, useEffect } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetSurveyTemplateByIdQuery } from '../../../../store/api/survey.api';
import { SurveyQuestionRender } from '../../components/question/question.component';
import SurveyHeader from '../../../_core/components/header-survey/header-survey.component';

export const SurveyPreviewPage: FC = () => {
	const [t, i18n] = useTranslation();
	const { surveyTemplateId } = useParams();

	const { data: surveyTemplate } = useGetSurveyTemplateByIdQuery(surveyTemplateId ?? '');

	const browserLanguage = navigator.language;
	const isArabic = surveyTemplate?.language === 'Arabic' || browserLanguage === 'ar';

	useEffect(() => {
		const browserLanguage = navigator.language;

		const isArabicLanguage = surveyTemplate?.language === 'Arabic';
		const isSpanishLanguage = surveyTemplate?.language === 'Spanish' || surveyTemplate?.language === 'Spanish (Spain)';

		const selectedLanguage = isArabicLanguage
			? 'ar'
			: isSpanishLanguage
			? 'es'
			: browserLanguage === 'ar'
			? 'ar'
			: browserLanguage === 'es'
			? 'es'
			: 'en';

		i18n.changeLanguage(selectedLanguage);
	}, [i18n, surveyTemplate?.language]);

	return (
		<Box
			sx={{
				display: 'flex',
				flex: 1,
				justifyContent: 'center',
				minHeight: 0,
				height: '100%',
				direction: isArabic ? 'rtl' : 'ltr',
				textAlign: isArabic ? 'right' : 'left',
			}}
		>
			<Box sx={{ width: '100%', height: '100%' }}>
				<Box component="header" sx={{ mb: 1 }}>
					<SurveyHeader title={surveyTemplate?.title ?? 'Survey'} />
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
					<Paper elevation={0} sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
						<Typography fontSize={14} sx={{ fontWeight: '500', mb: '10px' }}>
							{t('survey-templates.forms.placeholders.survey-name')}
							<span style={{ color: 'red', marginLeft: '4px', fontWeight: '300' }}>*</span>
						</Typography>
						<CoreTextField
							requiredMark
							fullWidth
							placeholder={t('survey-templates.forms.placeholders.survey-name') ?? ''}
						/>
					</Paper>
					{surveyTemplate?.questions?.map((question, index) => (
						<Paper key={question._id.toString()} elevation={0} sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
							<Typography fontSize={14} sx={{ fontWeight: '500', mb: '10px' }}>
								{index + 1}. {question.text}{' '}
								{question.isRequired && <span style={{ color: 'red', marginLeft: '4px', fontWeight: '300' }}>*</span>}
							</Typography>
							<SurveyQuestionRender question={question} />
						</Paper>
					))}
				</Box>
			</Box>
		</Box>
	);
};
