import { IsEmail, IsEnum, IsOptional, IsPhoneNumber, Length, IsBoolean } from 'class-validator';
import { UserRolesEnum } from '../../enums';

export class UserInfoDto {
	@IsEmail()
	email: string;

	@Length(1, 100)
	name: string;
}
