import React, { forwardRef } from 'react';

import { InputAdornment, OutlinedInputProps } from '@mui/material';
import { CoreInput, CoreInputProps } from '../../core-input';
import { FilterIcon } from '../../../../constants/icons.constants';
import ClearIcon from '@mui/icons-material/Clear';
import { CoreIconButton } from '../../core-button';
import { CoreFormControl, CoreFormControlProps } from '../../core-form-control';

export interface CalendarInputProps extends Omit<OutlinedInputProps, 'label'>, CoreFormControlProps {
	onClear?: () => void;
	showFilterIcon?: boolean;
}

const CalendarInput = forwardRef<HTMLInputElement, CoreInputProps & CalendarInputProps>((props, ref) => {
	const {
		value,
		onClear,
		id,
		fullWidth,
		controlSx,
		label,
		helperText,
		error,
		disabled,
		requiredMark,
		showFilterIcon = true,
	} = props;

	return (
		<CoreFormControl
			disabled={disabled}
			error={error}
			helperText={helperText}
			label={label}
			id={id}
			requiredMark={requiredMark}
			fullWidth={fullWidth}
			controlSx={controlSx}
		>
			<CoreInput
				{...props}
				sx={{
					paddingRight: '16px',
					'& .MuiInputAdornment-root': {
						color: ({ colors }) => (value ? colors.blue : colors.gray),
					},
					'&.Mui-focused .MuiInputAdornment-root': {
						color: (theme) => theme.colors.blue,
					},
					'&.Mui-disabled .MuiInputAdornment-root': {
						color: (theme) => theme.colors.blueTransparent,
					},
					...props.sx,
				}}
				inputRef={ref}
				placeholder="Choose duration"
				endAdornment={
					<InputAdornment position="end">
						<>
							{value && (
								<CoreIconButton
									onClick={onClear}
									sx={{
										marginRight: '4px',
										color: ({ colors }) => colors.gray,
									}}
								>
									<ClearIcon />
								</CoreIconButton>
							)}
							{showFilterIcon && <FilterIcon />}
						</>
					</InputAdornment>
				}
			/>
		</CoreFormControl>
	);
});

export default CalendarInput;
