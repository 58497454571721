import { FC, useRef, useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SearchIcon from '@mui/icons-material/Search';
import { Box, Chip, Container, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';

import { CoreInput } from '../../../_core/components/_ui/core-input';
import {
	useCreateProviderMutation,
	useDeleteProviderMutation,
	useGetAllProvidersByDistrictIdQuery,
	useImportProvidersMutation,
	useUpdateProviderMutation,
} from '../../../../store/api/providers.api';
import { useSource, useSortingParams, useInitiateState, useLanguageFilter, useMuiModal } from '../../../_core/hooks';
import { ProvidersDistrictTable } from '../../components/provider-district-table/provider-district-table.component';
import { LanguageEnum, ProviderDto, ProviderInfoDto } from '@families-link/shared';
import { DistrictRoutes } from '../../../district/constants';
import { getLocalStorageDistrictId, setLocalStorageProviderId } from '../../../_core/utils';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { AutocompleteTextField, CoreAutoComplete } from '../../../_core/components/_ui/core-autocomplete';
import { getObjectLanguage } from '../../../_core/components/languages-component';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useSnackbar } from 'notistack';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import AddIcon from '@mui/icons-material/Add';
import { AddProviderForm } from '../../components/create-provider-form/create-provider-form.component';
import { EditProviderForm } from '../../components/edit-provider-form/edit-provider-form.component';
import { DeleteProviderModal } from '../../components/delete-provider-modal/delete-provider-modal.component';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { ProviderImportModal } from '../../components/modal-import-form/modal-import-form.component';
import { CoreModal } from '../../../_core/components/_ui/core-modal';
import { containerStyles } from '../../../_core/components/_ui/core-modal/core-modal.styles';
import { FilterIcon } from '../../../_core/constants/icons.constants';

export const ProvidersListPage: FC = () => {
	useInitiateState('district');
	const { register, source } = useSource();
	const { sortingArray, sortingParams, handleSortingChange } = useSortingParams();
	const { register: registerLang, getValues, control, languages } = useLanguageFilter();

	const navigate = useNavigate();
	const location = useLocation();

	const district: string = getLocalStorageDistrictId() || '';
	const {
		data: providers,
		error,
		isFetching,
		refetch,
	} = useGetAllProvidersByDistrictIdQuery({
		districtId: district,
		languages: languages.join(','),
		sortBy: sortingParams.orderField,
		sortOrder: sortingParams.order,
		searchQuery: source,
	});

	useEffect(() => {
		refetch();
	}, [location]);

	const [t] = useTranslation();

	const { enqueueSnackbar } = useSnackbar();

	const [createProvider] = useCreateProviderMutation();
	const [updateProvider] = useUpdateProviderMutation();
	const [deleteProvider] = useDeleteProviderMutation();

	const [dialogOpen, setDialogOpen] = useState(false);
	const [editDialogOpen, setEditDialogOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const [selectedProvider, setSelectedProvider] = useState<ProviderDto | null>(null);

	const resolverCreate = classValidatorResolver(ProviderInfoDto);

	const defaultValues = {
		fullName: '',
		targetLanguage: [],
		verificationType: undefined,
		workStatus: '',
		districtId: getLocalStorageDistrictId(),
		servieType: '',
	};

	const handleAddProvider = useCallback(
		async (formData: FormData) => {
			await createProvider(formData).unwrap();
			enqueueSnackbar('Provider added successfully!', { variant: 'success' });
			setDialogOpen(false);

			refetch();
		},
		[createProvider, refetch, enqueueSnackbar]
	);

	const handleOnEdit = (provider: ProviderDto) => {
		setSelectedProvider(provider);
		setEditDialogOpen(true);
	};

	const resolverUpdate = classValidatorResolver(ProviderInfoDto);

	const handleOnEditProvider = useCallback(
		async (updatedProvider: FormData) => {
			try {
				const providerId = updatedProvider?.get('_id')?.toString();
				if (providerId) {
					await updateProvider({ id: providerId, updateData: updatedProvider }).unwrap();
					setEditDialogOpen(false);
					setSelectedProvider(null);
					enqueueSnackbar('Provider updated successfully!', { variant: 'success' });
					refetch();
				} else {
					console.error('Provider ID is null or undefined');
				}
			} catch (error) {
				console.error('Failed to update provider:', error);
			}
		},
		[updateProvider, refetch, enqueueSnackbar, setEditDialogOpen, setSelectedProvider]
	);

	const handleOnDelete = useCallback(
		async (providerId: string) => {
			try {
				await deleteProvider(providerId).unwrap();
				enqueueSnackbar('Provider deleted successfully!', { variant: 'success' });
				refetch();
			} catch (error) {
				console.log(error);
			}
		},
		[deleteProvider, refetch, enqueueSnackbar]
	);

	const openDeleteModal = (provider: ProviderDto) => {
		setSelectedProvider(provider);
		setDeleteDialogOpen(true);
	};

	const handleOnView = (provider: ProviderDto) => {
		setLocalStorageProviderId(provider._id);
		navigate(DistrictRoutes.Providers.Details.replace(':providerId', provider._id));
	};

	const [modalOpen, setModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState<React.ReactNode | null>(null);

	const [importProviders] = useImportProvidersMutation();

	const { openModal, closeModal } = useMuiModal(ProviderImportModal);

	const handleOpenModal = () => {
		openModal({
			submitAction: async (formData: FormData) => {
				enqueueSnackbar(t('providers.import-start'), {
					variant: 'info',
					autoHideDuration: 3000,
				});
				closeModal();
				try {
					const districtId = getLocalStorageDistrictId() || undefined;

					const result = await importProviders({ formData, districtId }).unwrap();

					const { success, failed } = result;

					const successCount = success.length;
					const failureCount = failed.length;
					const total = successCount + failureCount;

					const message = (
						<>
							<Typography variant="body1" fontWeight="bold">
								{successCount} of {total} providers were successfully imported.
							</Typography>
							{failureCount > 0 && (
								<Box mt={2}>
									<Typography variant="body1" fontWeight="bold">
										{t('providers.import-providers')}
									</Typography>
									{failed.map((f: any, index: number) => (
										<Box key={index} mt={1}>
											<Typography variant="body2">- {f.row['Full name']}</Typography>
											{f.error && (
												<Box ml={2}>
													<Typography variant="body2" color="error">
														Reasons:
													</Typography>

													{f.error.split(';').map((reason: string, idx: number) => (
														<MenuItem key={idx}>
															<Typography variant="body2" color="error">
																{reason.trim()}
															</Typography>
														</MenuItem>
													))}
												</Box>
											)}
										</Box>
									))}
								</Box>
							)}
						</>
					);

					setModalContent(message);
					setModalOpen(true);
				} catch (error) {
					console.error('File Import Error:', error);
					setModalContent(
						<Typography variant="body1" color="error">
							{t('providers.import-error')}
						</Typography>
					);
					setModalOpen(true);
				}
			},
			closeModal,
			formTitle: 'Import Providers',
			submitButtonName: 'Import',
		});
	};

	const scrollRef = useRef<HTMLDivElement>(null);

	return (
		<Container
			maxWidth={false}
			sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px' }}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'flex-start',
					width: '100%',
				}}
			>
				<Box>
					<CoreInput
						sx={{ marginRight: '16px' }}
						{...register('source')}
						placeholder={t('common.search-by-name')}
						endAdornment={
							<InputAdornment position="end">
								<SearchIcon />
							</InputAdornment>
						}
					/>
					<CoreAutoComplete
						multiple={true}
						{...registerLang('languages')}
						control={control}
						controlSx={{ minWidth: '220px', marginRight: '16px' }}
						IconComponent={<FilterIcon />}
						options={Object.values(LanguageEnum)}
						getOptionLabel={(option) => {
							const language = getObjectLanguage(option);
							return language ? language.name : '';
						}}
						selectOnFocus={true}
						isOptionEqualToValue={(option, value) => option === value}
						renderInput={(params) => (
							<AutocompleteTextField
								{...params}
								placeholder={!getValues('languages')?.length ? t('common.filter-lang') : ''}
								sx={{
									'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
										padding: '4.5px 4px 5.5px 5px',
										minWidth: 0,
									},
									'& .MuiInputBase-input': {
										height: '100%',
										padding: '0',
									},
									'& .MuiOutlinedInput-root': {
										'&.Mui-focused fieldset': {
											borderColor: (theme) => theme.colors.blue,
										},
									},
								}}
							/>
						)}
						renderOption={(props, option) => {
							const language = getObjectLanguage(option);

							return (
								<MenuItem {...props} key={language?.value}>
									{language?.label}
								</MenuItem>
							);
						}}
						renderTags={(value, getTagProps) => {
							const count = value.length - 1;
							return [
								value[0] && <Chip {...getTagProps({ index: 0 })} label={getObjectLanguage(value[0])?.name} />,
								count > 0 && <Chip {...getTagProps({ index: count })} label={`+${count} more`} />,
							];
						}}
					/>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto', alignItems: 'center' }}>
					<CoreButton
						sx={{ display: 'flex', gap: 1, marginRight: '8px' }}
						variant="base"
						circle
						onClick={handleOpenModal}
					>
						<FileUploadOutlinedIcon color="primary" />
					</CoreButton>
					<CoreModal
						open={modalOpen}
						containerSx={containerStyles}
						bodySx={{ maxHeight: 400, overflowY: 'auto' }}
						onClose={() => setModalOpen(false)}
						hideCloseIcon={false}
					>
						{modalContent}
						<Box mt={3} display="flex" justifyContent="flex-end">
							<CoreButton onClick={() => setModalOpen(false)}>OK</CoreButton>
						</Box>
					</CoreModal>

					<CoreButton
						onClick={() => setDialogOpen(true)}
						sx={{
							display: 'flex',
							width: '180px',
							fontSize: '16px',
							padding: '12px 24px',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '8px',
						}}
					>
						<AddIcon /> {t('providers.add-provider')}
					</CoreButton>
				</Box>
			</Box>
			<Box sx={{ marginTop: 2, height: '95%', overflowY: 'auto' }} ref={scrollRef}>
				<ProvidersDistrictTable
					data={providers || []}
					loading={isFetching}
					sorting={sortingArray}
					onSortingChange={handleSortingChange}
					onView={handleOnView}
					onDelete={openDeleteModal}
					onEdit={handleOnEdit}
				/>
			</Box>

			<AddProviderForm
				open={dialogOpen}
				onClose={() => setDialogOpen(false)}
				submitAction={handleAddProvider}
				resolver={resolverCreate}
				defaultValues={defaultValues}
			/>

			{selectedProvider && (
				<EditProviderForm
					open={editDialogOpen}
					onClose={() => setEditDialogOpen(false)}
					submitAction={handleOnEditProvider}
					resolver={resolverUpdate}
					defaultValues={selectedProvider}
				/>
			)}

			{selectedProvider && (
				<DeleteProviderModal
					open={deleteDialogOpen}
					onClose={() => setDeleteDialogOpen(false)}
					providerId={selectedProvider._id}
					providerName={selectedProvider.fullName}
					onSubmit={handleOnDelete}
				/>
			)}
		</Container>
	);
};
