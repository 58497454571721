import { FC, useEffect } from 'react';

import { Paper, Box, Typography } from '@mui/material';

import { useInitiateState, usePaginatedData, useSortingParams } from '../../../_core/hooks';
import { ProvidersDetails } from '../../components/providers-details/providers-details.component';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import {
	useDeleteEngagementMutation,
	useGetEngagementsByProviderIdQuery,
	useUpdateEngagementMutation,
} from '../../../../store/api/engagements.api';
import { EngagementDto, UpdateEngagementDto } from '@families-link/shared';
import { EngagementsTable } from '../../../engagement/components/engagements-table';
import {
	getLocalStorageDistrictId,
	getLocalStorageProviderId,
	setLocalStorageEngagementId,
} from '../../../_core/utils';
import { useTranslation } from 'react-i18next';
import { useGetAllFieldsQuery } from '../../../../store/api/customRequirements.api';
import { useGetAllProvidersQuery } from '../../../../store/api/providers.api';
import { useGetAllFamiliesByDistrictIdQuery } from '../../../../store/api/families.api';
import { useOpenModalForm } from '../../../engagement/hooks/modal-forms/use-open-modal-form.hook';
import { useOpenConfirmModal } from '../../../engagement/hooks/modal-forms/use-open-confirm-modal-form.hook';
import { DeleteIcon } from '../../../_core/components/icons';
import { useNavigate } from 'react-router-dom';
import { DistrictRoutes } from '../../../district/constants';

export const ProvidersDetailsPage: FC = () => {
	useInitiateState('provider');

	const provider = getLocalStorageProviderId();
	const currentDistrict = getLocalStorageDistrictId();

	const navigate = useNavigate();

	const [t] = useTranslation();
	const { data: fieldsListFromDistrict } = useGetAllFieldsQuery({ districtId: currentDistrict || '' });
	const { data: providersList } = useGetAllProvidersQuery({
		sortBy: undefined,
		sortOrder: undefined,
		searchQuery: undefined,
	});
	const { data: familiesList } = useGetAllFamiliesByDistrictIdQuery({
		district: currentDistrict,
	});

	const { sortingArray, sortingParams, handleSortingChange } = useSortingParams();
	const { requestObject, meta, page, handleResetPage, itemsList, fetchMore } = usePaginatedData<EngagementDto>(
		useGetEngagementsByProviderIdQuery,
		{
			...sortingParams,
			providerId: provider,
		}
	);
	const { refetch, isFetching } = requestObject;

	useEffect(() => {
		handleResetPage();
	}, [sortingArray]);

	const handlePagination = () => {
		handleResetPage();

		if (page === 1) {
			refetch();
		}
	};

	const [updateEngagement] = useUpdateEngagementMutation();
	const featchActionEdit = async (data: any) => {
		await updateEngagement({ id: data._id, data }).unwrap();
	};
	const handleOpenModalEdit = useOpenModalForm({
		featchAction: featchActionEdit,
		handlePagination,
		isCreate: false,
		submitButtonName: t('engagements.forms.buttons.save'),
		toastText: 'Engagement record was successfully updated',
		fieldsListFromDistrict,
		providersList,
		familiesList,
	});

	const handleOnView = (engagement: EngagementDto) => {
		setLocalStorageEngagementId(engagement._id);
		navigate(DistrictRoutes.Engagements.Details.replace(':engagementId', engagement._id));
	};

	const [deleteEngagement] = useDeleteEngagementMutation();
	const featchActionDelete = async (data: EngagementDto) => {
		await deleteEngagement({ id: data._id }).unwrap();
	};
	const handleOpenDeleteConfirmModal = useOpenConfirmModal({
		featchAction: featchActionDelete,
		handlePagination,
		title: t('engagements.forms.delete-title'),
		description: t('engagements.forms.delete-description'),
		icon: <DeleteIcon sx={{ fontSize: 40 }} />,
		danger: true,
		submitButtonName: t('engagements.forms.buttons.delete'),
		toastText: 'Engagement record was successfully deleted',
	});

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
			<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', height: '32%' }}>
				<Paper elevation={0} sx={{ width: '75%', mr: 1, p: 1, pb: 2 }}>
					<ProvidersDetails />
				</Paper>
				<Paper elevation={0} sx={{ width: '25%' }}>
					<Box sx={{ display: 'flex', flexDirection: 'column', m: 3, gap: 2 }}>
						<PeopleAltIcon sx={{ fontSize: '32px' }} color="primary" />
						<Typography
							sx={{
								color: (theme) => theme.colors.blue,
								fontStyle: 'normal',
								fontSize: '56px',
								fontWeight: 'bold',
								lineHeight: '100%',
							}}
						>
							{meta?.itemCount}
						</Typography>
						<Typography sx={{ fontWeight: 'bold', color: (theme) => theme.colors.gray }}>Total Engagements</Typography>
					</Box>
				</Paper>
			</Box>
			<Paper
				elevation={0}
				sx={{
					flex: 1,
					p: 3,
					display: 'flex',
					height: '70%',
					mt: 1,
					borderBottomRightRadius: 0,
					borderBottomLeftRadius: 0,
				}}
			>
				<EngagementsTable
					data={itemsList}
					loading={isFetching}
					hasMore={meta?.hasNextPage}
					onDelete={handleOpenDeleteConfirmModal}
					onEdit={handleOpenModalEdit}
					onView={handleOnView}
					sorting={sortingArray}
					onSortingChange={handleSortingChange}
					fetchMore={fetchMore}
					permission={{ isDetailsPage: true }}
				/>
			</Paper>
		</Box>
	);
};
