import { RouteObject } from 'react-router-dom';
import { SurveyViewPage } from '../survey/pages/survey-view/survey-view.page';
import { SurveyRoutes } from './constants';

export const SurveyModuleRouter: RouteObject[] = [
	{
		path: SurveyRoutes.Root,
		element: <SurveyViewPage />,
		handle: {
			title: 'headers.survey',
			needsDrawer: false,
		},
	},
	{
		path: SurveyRoutes.ManualEditing,
		element: <SurveyViewPage />,
		handle: {
			title: 'headers.survey',
			needsDrawer: false,
		},
	},
];
