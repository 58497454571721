import React from 'react';
import { Box, Typography } from '@mui/material';
import { SurveyAnswerValueDto, SurveyAnswerValueInterface, SurveyQuestionTypeEnum } from '@families-link/shared';
import { useTranslation } from 'react-i18next';

interface SurveyAnswerRenderProps {
	answer: SurveyAnswerValueDto;
	order: number;
}

const getAnswerStyleComponent = (answer: SurveyAnswerValueDto, order: number) => {
	const { question, questionType, value } = answer;

	switch (questionType) {
		case SurveyQuestionTypeEnum.ShortText:
		case SurveyQuestionTypeEnum.LongText:
			return <Typography sx={{ paddingY: 1 }}>{value}</Typography>;
		case SurveyQuestionTypeEnum.Options:
			return (
				<Box sx={{ display: 'flex', paddingY: 1 }}>
					<Box
						sx={{
							width: '20px',
							height: '20px',
							border: (theme) => `2px solid ${theme.colors.blue}`,
							borderRadius: '50%',
							backgroundColor: 'transparent',
							display: 'inline-block',
							position: 'relative',
							'&::after': {
								content: '""',
								width: '10px',
								height: '10px',
								backgroundColor: (theme) => `${theme.colors.blue}`,
								borderRadius: '50%',
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								zIndex: 10,
							},
						}}
					></Box>
					<Typography sx={{ paddingLeft: 1 }}>{value}</Typography>
				</Box>
			);
		case SurveyQuestionTypeEnum.MultiSelect:
			return (
				<Box sx={{ diplay: 'flex', paddingY: 1 }}>
					<Box
						sx={{
							width: '20px',
							height: '20px',
							border: (theme) => `2px solid ${theme.colors.blue}`,
							borderRadius: '8px',
							backgroundColor: (theme) => theme.colors.blue,
							display: 'inline-block',
							position: 'relative',
							'&::after': {
								content: '"✔"',
								width: '10px',
								height: '5px',
								color: (theme) => `${theme.colors.white}`,
								position: 'absolute',
								top: '0',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								zIndex: 10,
							},
						}}
					></Box>
					<Typography sx={{ paddingLeft: 1 }}>{value}</Typography>
				</Box>
			);
		default:
			return null;
	}
};

export const SurveyAnswerRender: React.FC<SurveyAnswerRenderProps> = ({ answer, order }) => {
	const { question, value } = answer;

	return (
		<Box sx={{ paddingY: 1 }}>
			<Typography sx={{ fontWeight: 'bold', paddingLeft: 1 }}>{`${order}. ${question}`}</Typography>
			<Typography sx={{ paddingY: 1 }}>
				{
					<Box component="span" sx={{ fontWeight: 'bold', paddingRight: 1 }}>
						Answer:{' '}
					</Box>
				}
				{value}
			</Typography>
		</Box>
	);
};
