import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import { Avatar, Box, Chip, Container, InputAdornment, MenuItem, Typography } from '@mui/material';
import { DeleteOutlineOutlined, Search, Add } from '@mui/icons-material';

import { EngagementDto, LanguageEnum, PermissionDto } from '@families-link/shared';

import {
	useArchiveEngagementMutation,
	useCreateEngagementMutation,
	useDeleteEngagementMutation,
	useExportEngagementsToCSVMutation,
	useGetEngagementsListQuery,
	useUnarchiveEngagementMutation,
	useUpdateEngagementMutation,
} from '../../../../store/api/engagements.api';

import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { EngagementsTable } from '../../components/engagements-table';
import { DistrictRoutes } from '../../../district/constants';
import {
	useSource,
	useSortingParams,
	usePaginatedData,
	useLanguageFilter,
	useProviderFilter,
	useDurationFilter,
} from '../../../_core/hooks';
import { AutocompleteTextField, CoreAutoComplete } from '../../../_core/components/_ui/core-autocomplete';
import { getObjectLanguage } from '../../../_core/components/languages-component';
import { useOpenModalForm } from '../../hooks/modal-forms/use-open-modal-form.hook';
import { useOpenConfirmModal } from '../../hooks/modal-forms/use-open-confirm-modal-form.hook';
import { getLocalStorageDistrictId, setLocalStorageEngagementId } from '../../../_core/utils';
import { useGetAllFieldsQuery } from '../../../../store/api/customRequirements.api';
import { useGetAllProvidersByDistrictIdQuery } from '../../../../store/api/providers.api';
import { useGetAllFamiliesByDistrictIdQuery } from '../../../../store/api/families.api';
import { ArchiveIcon, Download, ArchiveWhiteIcon, FilterIcon } from '../../../_core/constants/icons.constants';
import { selectListTypographyOverflowStyles } from '../../../_core/styles';
import CoreCalendar from '../../../_core/components/_ui/core-date-picker/core-calendar/core-calendar.component';

export type EngagementsListPageProps = {
	permission?: PermissionDto;
};

export const EngagementsListPage: FC<EngagementsListPageProps> = (props) => {
	const { permission } = props;

	const { enqueueSnackbar } = useSnackbar();

	const [t] = useTranslation();
	const navigate = useNavigate();
	const { register, source } = useSource();
	const { sortingArray, sortingParams, handleSortingChange } = useSortingParams();
	const { register: registerLang, getValues: getValuesLang, control: controlLang, languages } = useLanguageFilter();
	const { name: nameDuration, control: controlDuration, currentDuration, transformDuration } = useDurationFilter();
	const [startDate, setStartDate] = useState<Date | null>(
		transformDuration?.start ? new Date(transformDuration.start) : null
	);
	const [endDate, setEndDate] = useState<Date | null>(transformDuration?.end ? new Date(transformDuration.end) : null);

	const {
		register: registerProvider,
		getValues: getValuesProvider,
		control: controlProvider,
		providers,
	} = useProviderFilter();
	const currentDistrict = getLocalStorageDistrictId();

	const { data: fieldsListFromDistrict } = useGetAllFieldsQuery({ districtId: currentDistrict || '' });
	const { data: providersList } = useGetAllProvidersByDistrictIdQuery({
		districtId: currentDistrict || '',
		languages: undefined,
		sortBy: undefined,
		sortOrder: undefined,
		searchQuery: undefined,
	});
	const { data: familiesList } = useGetAllFamiliesByDistrictIdQuery({
		district: currentDistrict,
	});

	const [showArchived, setShowArchived] = useState(false);

	const { requestObject, meta, page, handleResetPage, itemsList, fetchMore } = usePaginatedData<EngagementDto>(
		useGetEngagementsListQuery,
		{
			source,
			...sortingParams,
			languages: languages.join(','),
			providers: providers.join(','),
			district: currentDistrict,
			archived: showArchived,
			start: startDate?.toISOString(),
			end: endDate?.toISOString(),
		}
	);
	const { refetch, isFetching, isLoading, isSuccess } = requestObject;

	useEffect(() => {
		handleResetPage();
	}, [sortingArray, source]);

	useEffect(() => {
		refetch();
	}, [showArchived]);

	const handlePagination = () => {
		handleResetPage();

		if (page === 1) {
			refetch();
		}
	};

	const [createEngagement] = useCreateEngagementMutation();
	const featchActionCreate = async (data: any) => {
		await createEngagement(data).unwrap();
	};
	const handleOpenModalCreate = useOpenModalForm({
		featchAction: featchActionCreate,
		handlePagination,
		isCreate: true,
		submitButtonName: t('engagements.forms.buttons.create'),
		toastText: 'Engagement record was successfully created',
		fieldsListFromDistrict,
		providersList,
		familiesList,
	});

	const [updateEngagement] = useUpdateEngagementMutation();
	const featchActionEdit = async (data: any) => {
		await updateEngagement({ id: data.get('_id')?.toString(), data }).unwrap();
	};
	const handleOpenModalEdit = useOpenModalForm({
		featchAction: featchActionEdit,
		handlePagination,
		isCreate: false,
		submitButtonName: t('engagements.forms.buttons.save'),
		toastText: 'Engagement record was successfully updated',
		fieldsListFromDistrict,
		providersList,
		familiesList,
	});

	const [deleteEngagement] = useDeleteEngagementMutation();
	const featchActionDelete = async (data: EngagementDto) => {
		await deleteEngagement({ id: data._id }).unwrap();
	};
	const handleOpenDeleteConfirmModal = useOpenConfirmModal({
		featchAction: featchActionDelete,
		handlePagination,
		title: t('engagements.forms.delete-title'),
		description: t('engagements.forms.delete-description'),
		icon: <DeleteOutlineOutlined />,
		danger: true,
		submitButtonName: t('engagements.forms.buttons.delete'),
		toastText: 'Engagement record was successfully deleted',
	});

	const handleOnView = (engagement: EngagementDto) => {
		setLocalStorageEngagementId(engagement._id);
		navigate(DistrictRoutes.Engagements.Details.replace(':engagementId', engagement._id));
	};

	const [archiveEngagement] = useArchiveEngagementMutation();

	const fetchActionArchive = async (data: EngagementDto) => {
		await archiveEngagement({ id: data._id }).unwrap();
	};

	const [unarchiveEngagement] = useUnarchiveEngagementMutation();

	const fetchActionUnarchive = async (data: EngagementDto) => {
		await unarchiveEngagement({ id: data._id }).unwrap();
	};

	const handleArchiveConfirmModal = useOpenConfirmModal({
		featchAction: fetchActionArchive,
		handlePagination,
		title: t('engagements.forms.archive-title'),
		description: t('engagements.forms.archive-description'),
		icon: <ArchiveWhiteIcon />,
		danger: true,
		submitButtonName: t('engagements.forms.buttons.archive'),
		toastText: 'Engagement record was successfully archived.',
	});

	const handleUnarchiveConfirmModal = useOpenConfirmModal({
		featchAction: fetchActionUnarchive,
		handlePagination,
		title: t('engagements.forms.unarchive-title'),
		description: t('engagements.forms.unarchive-description'),
		icon: <ArchiveWhiteIcon />,
		danger: true,
		submitButtonName: t('engagements.forms.buttons.unarchive'),
		toastText: 'Engagement record was successfully unarchived.',
	});

	const handleOnSendEmail = () => {
		console.log('onSendEmail');
	};
	const handleOnSendSMS = () => {
		console.log('onSendSMS');
	};

	const [exportEngagementsToCSV] = useExportEngagementsToCSVMutation();

	const handleExport = async () => {
		try {
			const response = await exportEngagementsToCSV({
				source,
				...sortingParams,
				languages: languages.join(','),
				providers: providers.join(','),
				district: currentDistrict || '',
				...transformDuration,
			}).unwrap();
			const fileUrl = response.fileUrl;

			enqueueSnackbar(t('engagements.export'), {
				variant: 'info',
				autoHideDuration: 3000,
			});

			const link = document.createElement('a');
			link.href = fileUrl;

			document.body.appendChild(link);

			link.click();
			document.body.removeChild(link);
		} catch (error) {
			console.error('Failed to export CSV:', error);
		}
	};

	const handleToggleArchived = () => {
		setShowArchived((prev) => !prev);
	};

	return (
		<Container
			maxWidth={false}
			sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px' }}
		>
			<Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px', width: '100%', flexWrap: 'nowrap' }}>
				<CoreTextField
					{...register('source')}
					placeholder={t('common.search')}
					endAdornment={
						<InputAdornment position="end">
							<Search />
						</InputAdornment>
					}
					sx={{ marginRight: '16px' }}
				/>
				<Box sx={{ marginRight: '16px' }}>
					<CoreAutoComplete
						multiple={true}
						{...registerLang('languages')}
						control={controlLang}
						controlSx={{
							minWidth: {
								xs: '180px',
								lg: '220px',
							},
						}}
						IconComponent={<FilterIcon />}
						options={Object.values(LanguageEnum)}
						getOptionLabel={(option) => {
							const language = getObjectLanguage(option);

							return language ? language.name : '';
						}}
						selectOnFocus={true}
						isOptionEqualToValue={(option, value) => option === value}
						renderInput={(params) => (
							<AutocompleteTextField
								{...params}
								placeholder={!getValuesLang('languages')?.length ? t('common.filter-lang') : ''}
								sx={{
									'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
										padding: '4.5px 4px 5.5px 5px',
										minWidth: 0,
									},
									'& .MuiInputBase-input': {
										height: '100%',
										padding: '0',
									},
									'& .MuiOutlinedInput-root': {
										'&.Mui-focused fieldset': {
											borderColor: (theme) => theme.colors.blue,
										},
									},
								}}
							/>
						)}
						renderOption={(props, option) => {
							const language = getObjectLanguage(option);

							return (
								<MenuItem {...props} key={language?.value}>
									{language?.label}
								</MenuItem>
							);
						}}
						renderTags={(value, getTagProps) => {
							const count = value.length - 1;

							return [
								value[0] && <Chip {...getTagProps({ index: 0 })} label={getObjectLanguage(value[0])?.name} />,
								count > 0 && <Chip {...getTagProps({ index: count })} label={`+${count} more`} />,
							];
						}}
					/>
				</Box>
				<Box sx={{ marginRight: '16px' }}>
					<CoreAutoComplete
						multiple={true}
						{...registerProvider('providers')}
						control={controlProvider}
						controlSx={{ minWidth: '180px' }}
						IconComponent={<FilterIcon />}
						options={providersList || []}
						getOptionLabel={(option) => option.fullName || ''}
						selectOnFocus={true}
						isOptionEqualToValue={(option, value) => option._id === value._id}
						renderInput={(params) => (
							<AutocompleteTextField
								{...params}
								placeholder={!getValuesProvider('providers')?.length ? t('common.filter-provider') : ''}
								sx={{
									'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
										padding: '4.5px 4px 5.5px 5px',
										minWidth: 0,
									},
									'& .MuiInputBase-input': {
										height: '100%',
										padding: '0',
									},
									'& .MuiOutlinedInput-root': {
										'&.Mui-focused fieldset': {
											borderColor: (theme) => theme.colors.blue,
										},
									},
								}}
							/>
						)}
						renderOption={(props, option) => (
							<MenuItem
								{...props}
								key={option._id}
								sx={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Avatar
									src={option.imageUrl}
									alt={option.fullName}
									style={{ width: 24, height: 24, marginRight: 18, borderRadius: '6px' }}
								/>
								<Typography sx={selectListTypographyOverflowStyles}>{option.fullName}</Typography>
							</MenuItem>
						)}
						renderTags={(value, getTagProps) => {
							const count = value.length - 1;

							return [
								value[0] && <Chip {...getTagProps({ index: 0 })} label={value[0]?.fullName} />,
								count > 0 && <Chip {...getTagProps({ index: count })} label={`+${count} more`} />,
							];
						}}
					/>
				</Box>
				<Box sx={{ width: '30%' }}>
					<Controller
						name={nameDuration}
						control={controlDuration}
						render={({ field }) => (
							<CoreCalendar
								{...field}
								selectsRange
								inline={false}
								startDate={startDate}
								endDate={endDate}
								onChange={(dates: [Date | null, Date | null]) => {
									const [start, end] = dates;
									setStartDate(start);
									setEndDate(end);
								}}
								onClear={() => {
									setStartDate(null);
									setEndDate(null);
									field.onChange([null, null]);
								}}
							/>
						)}
					/>
				</Box>
				<Box sx={{ marginLeft: 'auto', display: 'flex', gap: 1 }}>
					<CoreButton variant="base" circle onClick={handleExport}>
						<Download />
					</CoreButton>

					{permission?.create ? (
						<CoreButton
							circle
							onClick={() => {
								handleOpenModalCreate();
							}}
							sx={{
								marginLeft: 'auto',
								bgcolor: (theme) => theme.colors.orangeDeep,
								color: 'white',
							}}
						>
							<Add />
						</CoreButton>
					) : null}
					{showArchived ? (
						<CoreButton
							sx={{
								bgcolor: (theme) => theme.colors.lightBlue,
								'&:hover': {
									bgcolor: (theme) => theme.colors.lightBlue,
								},
							}}
							variant="base"
							circle
							onClick={handleToggleArchived}
						>
							<ArchiveWhiteIcon />
						</CoreButton>
					) : (
						<CoreButton variant="base" circle onClick={handleToggleArchived}>
							<ArchiveIcon />
						</CoreButton>
					)}
				</Box>
			</Box>
			<EngagementsTable
				data={itemsList}
				loading={isFetching}
				hasMore={meta?.hasNextPage}
				permission={permission}
				onDelete={handleOpenDeleteConfirmModal}
				onEdit={handleOpenModalEdit}
				onView={handleOnView}
				onArchive={handleArchiveConfirmModal}
				onUnarchive={handleUnarchiveConfirmModal}
				onSendEmail={handleOnSendEmail}
				onSendSMS={handleOnSendSMS}
				sorting={sortingArray}
				onSortingChange={handleSortingChange}
				fetchMore={fetchMore}
			/>
		</Container>
	);
};
