import { Box, Typography, Button } from '@mui/material';
import { CoreModal, CoreModalProps } from '../../../_core/components/_ui/core-modal';
import { mainTitleModalWindowStyles } from '../../../_core/styles';
import { CopyAll as CopyIcon } from '@mui/icons-material';
import { modalBoxStyles } from '../modal-form/modal-form.styles';
import { CoreButton } from '../../../_core/components/_ui/core-button';

export type RegistrationLinkModalProps = CoreModalProps & {
	link: string;
	closeModal(): void;
};

export const RegistrationLinkModal: React.FC<RegistrationLinkModalProps> = ({ link, closeModal, ...modalProps }) => {
	const handleCopyLink = () => {
		navigator.clipboard.writeText(link).catch((error) => {
			console.error('Failed to copy the link: ', error);
		});
	};

	return (
		<CoreModal {...modalProps} bodySx={{ pt: 0 }} paperSx={{ width: '100%' }}>
			<Typography variant="h5" sx={mainTitleModalWindowStyles}>
				Registration Link
			</Typography>
			<Box sx={modalBoxStyles}>
				<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<Button onClick={handleCopyLink} sx={{ textAlign: 'start' }}>
						<Typography variant="body2" color="primary" style={{ wordBreak: 'break-word' }}>
							{link}
						</Typography>
						<CopyIcon color="primary" />
					</Button>
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<CoreButton onClick={closeModal} color="primary">
						OK
					</CoreButton>
				</Box>
			</Box>
		</CoreModal>
	);
};
