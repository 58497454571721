import React, { FC } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FamilyWithSurveyDto, SurveyAnswerDto, SurveyStatusEnum } from '@families-link/shared';
import { FamilyDetails } from '../../../family/components/family-details/family-details.component';
import { SurveyResultsAnswerList } from './survey-answers-list.component';
import { CreateSurvey } from './survey-create.component';
import { SendSurvey } from './survey-send.component';
import { ManualEditingSurvey } from './survey-manual-editing.component copy';
import { useGetFamilyWithSurveyQuery, useGetSurveyTemplateByIdQuery } from '../../../../store/api/survey.api';
import { CoreLoadingWall } from '../../../_core/components/_ui/core-loading-wall';

export type ResultsSurveyProps = {
	currentEngagementId?: string;
	currentFamilyWithSurvey?: FamilyWithSurveyDto;
	surveyAnswer?: SurveyAnswerDto;
	handleOpenModalCreate: () => void;
	handleOnManualEditing: (f?: FamilyWithSurveyDto) => Promise<void>;
	handleSubmitSend: (f?: FamilyWithSurveyDto) => Promise<void>;
	closeResultsComponent: () => void;
};

export const ResultsSurvey: FC<ResultsSurveyProps> = (props) => {
	const {
		currentFamilyWithSurvey,
		currentEngagementId,
		handleOpenModalCreate,
		handleSubmitSend,
		handleOnManualEditing,
		closeResultsComponent,
	} = props;
	const [t] = useTranslation();
	const { data: updatedFamilyWithSurvey, isLoading } = useGetFamilyWithSurveyQuery({
		familyId: currentFamilyWithSurvey?.family._id ?? '',
		engagementId: currentEngagementId ?? '',
	});

	if (isLoading) {
		return (
			<Container
				maxWidth={false}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100%',
					position: 'relative',
				}}
			>
				<CoreLoadingWall />
			</Container>
		);
	}

	return (
		<Container
			maxWidth={false}
			sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px', position: 'relative' }}
		>
			<Box sx={{ height: '32%' }}>
				<Button
					onClick={closeResultsComponent}
					sx={{
						height: '35px',
						width: '35px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						minWidth: 0,
						position: 'absolute',
						top: '4px',
						left: '4px',
						zIndex: 10,
						borderRadius: '50%',
						fontSize: '20px',
						color: (theme) => theme.colors.grayBlue,
						'&:hover': { backgroundColor: (theme) => theme.colors.grayLight },
					}}
				>{`<`}</Button>
				<Box>
					<Typography variant="h6" sx={{ fontWeight: 'bold', marginY: '8px', marginLeft: '24px' }}>
						{t('families.family-contact')}
					</Typography>
				</Box>
				<FamilyDetails familyId={currentFamilyWithSurvey?.family._id} isEngagementPage />
			</Box>
			{updatedFamilyWithSurvey?.surveyStatus === SurveyStatusEnum.Completed ? (
				<SurveyResultsAnswerList surveyAnswer={updatedFamilyWithSurvey?.surveyAnswer} />
			) : updatedFamilyWithSurvey?.surveyStatus === SurveyStatusEnum.NoSurvey ? (
				<CreateSurvey handleOpenModalCreate={handleOpenModalCreate} />
			) : updatedFamilyWithSurvey?.surveyStatus === SurveyStatusEnum.NotShared ? (
				<SendSurvey
					surveyTemplateName={updatedFamilyWithSurvey?.surveyTemplate?.title}
					currentFamilyWithSurvey={updatedFamilyWithSurvey}
					handleSubmitSend={handleSubmitSend}
				/>
			) : (
				<ManualEditingSurvey
					currentFamilyWithSurvey={updatedFamilyWithSurvey}
					handleOnManualEditing={handleOnManualEditing}
				/>
			)}
		</Container>
	);
};
