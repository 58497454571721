import React, { FC, useEffect, useRef, useState } from 'react';
import { FamiliesTable } from '../../components/families-table';
import { Box, Chip, Container, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import SearchIcon from '@mui/icons-material/Search';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { useTranslation } from 'react-i18next';
import {
	useGetFamiliesListQuery,
	useCreateFamilyMutation,
	useDeleteFamilyMutation,
	useUpdateFamilyMutation,
	useImportFamiliesMutation,
} from '../../../../store/api/families.api';
import { FamilyDto, LanguageEnum, PermissionDto, UserRolesEnum } from '@families-link/shared';
import { useNavigate } from 'react-router-dom';
import { DistrictRoutes } from '../../../district/constants';
import {
	useSource,
	useSortingParams,
	usePaginatedData,
	useLanguageFilter,
	useDistrictFilter,
	useMuiModal,
} from '../../../_core/hooks';
import { AutocompleteTextField, CoreAutoComplete } from '../../../_core/components/_ui/core-autocomplete';
import { getObjectLanguage } from '../../../_core/components/languages-component';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { useOpenModalForm } from '../../hooks/modal-forms/use-open-modal-form.hook';
import { useOpenConfirmModal } from '../../hooks/modal-forms/use-open-confirm-modal-form.hook';
import { getLocalStorageDistrictId, setLocalStorageFamilyId } from '../../../_core/utils';
import { useCurrentAccessTokenSelector } from '../../../auth';
import { useGetDistrictListWithoutPaginationQuery } from '../../../../store/api/district.api';
import { DeleteIcon } from '../../../_core/components/icons';
import { useSnackbar } from 'notistack';
import { CoreModal } from '../../../_core/components/_ui/core-modal';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { FamilyImportModal } from '../../components/modal-import-form/modal-import-form.component';
import { containerStyles } from '../../../_core/components/_ui/core-modal/core-modal.styles';
import { FilterIcon } from '../../../_core/constants/icons.constants';

export type FamiliesListPageProps = {
	permission?: PermissionDto;
};

export const FamiliesListPage: FC<FamiliesListPageProps> = (props) => {
	const { permission } = props;

	const [t] = useTranslation();
	const navigate = useNavigate();
	const { register, source } = useSource();

	const { enqueueSnackbar } = useSnackbar();

	const currentToken = useCurrentAccessTokenSelector();
	const isRequester = currentToken?.payload.sur === UserRolesEnum.Requester;

	const { sortingArray, sortingParams, handleSortingChange } = useSortingParams();
	const { register: registerLang, getValues, control, languages } = useLanguageFilter();
	const { data: districtList } = useGetDistrictListWithoutPaginationQuery();
	const {
		register: registerDistrict,
		getValues: getValuesDistrict,
		control: controlDistrict,
		districts,
	} = useDistrictFilter();

	const { requestObject, meta, page, handleResetPage, itemsList, fetchMore } = usePaginatedData<FamilyDto>(
		useGetFamiliesListQuery,
		{
			source,
			...sortingParams,
			languages: languages.join(','),
			district: getLocalStorageDistrictId(),
			districts: districts.join(','),
		}
	);
	const { refetch, isFetching } = requestObject;

	useEffect(() => {
		handleResetPage();
	}, [sortingArray, source]);

	const handlePagination = () => {
		handleResetPage();

		if (page === 1) {
			refetch();
		}
	};

	const [createFamily] = useCreateFamilyMutation();
	const featchActionCreate = async (data: FormData) => {
		await createFamily(data).unwrap();
	};
	const handleOpenModalCreate = useOpenModalForm({
		featchAction: featchActionCreate,
		handlePagination,
		isCreate: true,
		submitButtonName: t('families.forms.buttons.create'),
		toastText: 'Family contact was successfully created',
	});

	const [updateFamily] = useUpdateFamilyMutation();
	const featchActionEdit = async (data: FormData) => {
		await updateFamily({ id: data.get('_id')?.toString(), data }).unwrap();
	};
	const handleOpenModalEdit = useOpenModalForm({
		featchAction: featchActionEdit,
		handlePagination,
		isCreate: false,
		submitButtonName: t('families.forms.buttons.save'),
		toastText: 'Family contact was successfully updated',
	});

	const [deleteFamily] = useDeleteFamilyMutation();
	const featchActionDelete = async (data: FamilyDto) => {
		await deleteFamily({ id: data._id }).unwrap();
	};
	const handleOpenDeleteConfirmModal = useOpenConfirmModal({
		featchAction: featchActionDelete,
		handlePagination,
		title: t('families.forms.delete-title'),
		description: t('families.forms.delete-description'),
		icon: <DeleteIcon sx={{ fontSize: 40 }} />,
		danger: true,
		submitButtonName: t('families.forms.buttons.delete'),
		toastText: 'Family contact was successfully deleted',
	});

	const handleOnView = (family: FamilyDto) => {
		setLocalStorageFamilyId(family._id);
		navigate(DistrictRoutes.Families.Detailes.replace(':familyId', family._id));
	};

	const [modalOpen, setModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState<React.ReactNode | null>(null);

	const [importFamilies] = useImportFamiliesMutation();

	const { openModal, closeModal } = useMuiModal(FamilyImportModal);

	const handleOpenModal = () => {
		openModal({
			submitAction: async (formData: FormData) => {
				enqueueSnackbar(t('families.import-start'), {
					variant: 'info',
					autoHideDuration: 3000,
				});
				closeModal();
				try {
					const districtId = getLocalStorageDistrictId() || undefined;

					const result = await importFamilies({ formData, districtId }).unwrap();

					const { success, failed } = result;

					const successCount = success.length;
					const failureCount = failed.length;
					const total = successCount + failureCount;

					const message = (
						<>
							<Typography variant="body1" fontWeight="bold">
								{successCount} of {total} families were successfully imported.
							</Typography>
							{failureCount > 0 && (
								<Box mt={2}>
									<Typography variant="body1" fontWeight="bold">
										{t('families.import-families')}
									</Typography>
									{failed.map((f: any, index: number) => (
										<Box key={index} mt={1}>
											<Typography variant="body2">
												- {f.row['Full name']} ({f.row['email']})
											</Typography>
											{f.error && (
												<Box ml={2}>
													<Typography variant="body2" color="error">
														Reasons:
													</Typography>

													{f.error.split(';').map((reason: string, idx: number) => (
														<MenuItem key={idx}>
															<Typography variant="body2" color="error">
																{reason.trim()}
															</Typography>
														</MenuItem>
													))}
												</Box>
											)}
										</Box>
									))}
								</Box>
							)}
						</>
					);

					setModalContent(message);
					setModalOpen(true);
				} catch (error) {
					console.error('File Import Error:', error);
					setModalContent(
						<Typography variant="body1" color="error">
							{t('families.import-error')}
						</Typography>
					);
					setModalOpen(true);
				}
			},
			closeModal,
			formTitle: 'Import Families',
			submitButtonName: 'Import',
		});
	};

	return (
		<Container
			maxWidth={false}
			sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px' }}
		>
			<Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
				<CoreTextField
					{...register('source')}
					placeholder={t('common.search-by-name')}
					endAdornment={
						<InputAdornment position="end">
							<SearchIcon />
						</InputAdornment>
					}
					sx={{ marginRight: '16px' }}
				/>
				<CoreAutoComplete
					multiple={true}
					{...registerLang('languages')}
					control={control}
					controlSx={{ minWidth: '220px', marginRight: '16px' }}
					IconComponent={<FilterIcon />}
					options={Object.values(LanguageEnum)}
					getOptionLabel={(option) => {
						const language = getObjectLanguage(option);

						return language ? language.name : '';
					}}
					selectOnFocus={true}
					isOptionEqualToValue={(option, value) => option === value}
					renderInput={(params) => (
						<AutocompleteTextField
							{...params}
							placeholder={!getValues('languages')?.length ? t('common.filter-lang') : ''}
							sx={{
								'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
									padding: '4.5px 4px 5.5px 5px',
									minWidth: 0,
								},
								'& .MuiInputBase-input': {
									height: '100%',
									padding: '0',
								},
								'& .MuiOutlinedInput-root': {
									'&.Mui-focused fieldset': {
										borderColor: (theme) => theme.colors.blue,
									},
								},
							}}
						/>
					)}
					renderOption={(props, option) => {
						const language = getObjectLanguage(option);

						return (
							<MenuItem {...props} key={language?.value}>
								{language?.label}
							</MenuItem>
						);
					}}
					renderTags={(value, getTagProps) => {
						const count = value.length - 1;

						return [
							value[0] && <Chip {...getTagProps({ index: 0 })} label={getObjectLanguage(value[0])?.name} />,
							count > 0 && <Chip {...getTagProps({ index: count })} label={`+${count} more`} />,
						];
					}}
				/>
				{permission?.read ? (
					<CoreAutoComplete
						multiple={true}
						{...registerDistrict('districts')}
						control={controlDistrict}
						controlSx={{ minWidth: '190px' }}
						IconComponent={<FilterIcon />}
						options={districtList || []}
						getOptionLabel={(option) => option.name || ''}
						selectOnFocus={true}
						isOptionEqualToValue={(option, value) => option._id === value._id}
						renderInput={(params) => (
							<AutocompleteTextField
								{...params}
								placeholder={!getValuesDistrict('districts')?.length ? t('common.filter-district') : ''}
								sx={{
									'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
										padding: '4.5px 4px 5.5px 5px',
										minWidth: 0,
									},
									'& .MuiInputBase-input': {
										height: '100%',
										padding: '0',
									},
									'& .MuiOutlinedInput-root': {
										'&.Mui-focused fieldset': {
											borderColor: (theme) => theme.colors.blue,
										},
									},
								}}
							/>
						)}
						renderOption={(props, option) => (
							<MenuItem {...props} key={option._id}>
								<Typography>{option.name}</Typography>
							</MenuItem>
						)}
						renderTags={(value, getTagProps) => {
							const count = value.length - 1;

							return [
								value[0] && <Chip {...getTagProps({ index: 0 })} label={value[0]?.name} />,
								count > 0 && <Chip {...getTagProps({ index: count })} label={`+${count} more`} />,
							];
						}}
					/>
				) : null}

				{!isRequester && permission?.create ? (
					<>
						<CoreButton
							sx={{ marginLeft: 'auto', display: 'flex', gap: 1, marginRight: '8px' }}
							variant="base"
							circle
							onClick={handleOpenModal}
						>
							<FileUploadOutlinedIcon color="primary" />
						</CoreButton>
						<CoreModal
							open={modalOpen}
							containerSx={containerStyles}
							bodySx={{ maxHeight: 400, overflowY: 'auto' }}
							onClose={() => {
								setModalOpen(false);
							}}
							hideCloseIcon={false}
						>
							{modalContent}
							<Box mt={3} display="flex" justifyContent="flex-end">
								<CoreButton
									onClick={() => {
										setModalOpen(false);
									}}
								>
									OK
								</CoreButton>
							</Box>
						</CoreModal>

						<CoreButton
							onClick={() => {
								handleOpenModalCreate();
							}}
							sx={{ fontSize: '16px' }}
						>
							{t('families.add-family')}
						</CoreButton>
					</>
				) : null}
			</Box>
			<FamiliesTable
				data={itemsList}
				loading={isFetching}
				hasMore={meta?.hasNextPage}
				onDelete={handleOpenDeleteConfirmModal}
				onEdit={handleOpenModalEdit}
				onView={handleOnView}
				sorting={sortingArray}
				onSortingChange={handleSortingChange}
				fetchMore={fetchMore}
				permission={permission}
			/>
		</Container>
	);
};
