import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { FamilyWithSurveyDto } from '@families-link/shared';

export type SendSurveyProps = {
	surveyTemplateName?: string;
	currentFamilyWithSurvey?: FamilyWithSurveyDto;
	handleSubmitSend: (f?: FamilyWithSurveyDto) => Promise<void>;
};

export const SendSurvey: FC<SendSurveyProps> = (props) => {
	const { surveyTemplateName, currentFamilyWithSurvey, handleSubmitSend } = props;

	const [t] = useTranslation();

	const handleSendSurvey = () => {
		handleSubmitSend(currentFamilyWithSurvey);
	};

	return (
		<Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
			<Typography variant="h2" sx={{ fontSize: '24px', fontWeight: 'bold', marginTop: '8px', marginLeft: '16px' }}>
				{t('surveys.survey-results')}
			</Typography>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					flexGrow: 1,
					flexWrap: 'wrap',
					margin: 'auto',
				}}
			>
				<Typography variant="h3" sx={{ fontSize: '16px', fontWeight: 'bold', marginY: '8px' }}>
					{t('surveys.send-title')}
				</Typography>
				<Box sx={{ width: '65%', textAlign: 'center', marginY: '8px' }}>
					<Typography variant="body1">
						{t('surveys.send-description').replace(
							':SurveyTemplateName',
							surveyTemplateName ? `'${surveyTemplateName}'` : 'choosen template'
						)}
					</Typography>
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginY: '8px' }}>
					<CoreButton onClick={handleSendSurvey}>{t('surveys.send-button')}</CoreButton>
				</Box>
			</Box>
		</Box>
	);
};
