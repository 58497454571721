import { IsEnum, IsInt, IsOptional, Max, Min } from 'class-validator';
import { Type } from 'class-transformer';
import { OrderEnum } from '../../enums';

export class PageOptionsDto {
	@IsEnum(OrderEnum)
	@IsOptional()
	readonly order?: OrderEnum = OrderEnum.ASC;

	@IsOptional()
	readonly orderField?: string;

	@Type(() => Number)
	@IsInt()
	@Min(1)
	@IsOptional()
	readonly page: number = 1;

	@Type(() => Number)
	@IsInt()
	@Min(1)
	@Max(50)
	@IsOptional()
	readonly take: number = 20;

	get skip(): number {
		return (this.page - 1) * this.take;
	}
}
