import { SurveyQuestionInterface } from '@families-link/shared';
import { useMuiModal } from '../../../_core/hooks';
import { useCallback } from 'react';
import { SurveyModal, SurveyModalProps } from '../../components/modal-forms/survey-view.component';
import { FormProvider, useForm } from 'react-hook-form';

interface OpenSurveyModalProps {
	questions: SurveyQuestionInterface[];
	modalTitle?: string;
	language?: any;
}

export function useOpenSurveyModal({ questions, modalTitle = 'Survey', language }: OpenSurveyModalProps) {
	const methods = useForm();
	const { openModal, closeModal } = useMuiModal<SurveyModalProps>((props) => (
		<FormProvider {...methods}>
			<SurveyModal {...props} />
		</FormProvider>
	));

	const handleOpenSurveyModal = useCallback(() => {
		openModal({
			modalTitle,
			questions,
			language,
			onClose: closeModal,
		});
	}, [openModal, closeModal, modalTitle, questions, language]);

	return handleOpenSurveyModal;
}
