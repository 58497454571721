import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { UserRolesEnum } from '@families-link/shared';
import { useGetUserDistrictByIdQuery } from '../../../../store/api/users.api';
import { setLocalStorageDistrictId } from '../../utils';
import { DistrictRoutes } from '../../../district/constants';
import { useCurrentAccessTokenSelector } from '../../../auth';

export const DistributionPage: FC = () => {
	const currentToken = useCurrentAccessTokenSelector();
	const { data: userDistrictId } = useGetUserDistrictByIdQuery(currentToken?.payload.sub);

	if (userDistrictId) {
		setLocalStorageDistrictId(userDistrictId);

		return <Navigate to={DistrictRoutes.Dashboard.replace(':districtId', userDistrictId)} />;
	}

	if (UserRolesEnum.SuperAdmin === currentToken?.payload.sur) {
		return <Navigate to="/dashboard" />;
	}

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				paddingTop: '15%',
			}}
		>
			LOADING...
		</Box>
	);
};
