import React, { FC, useCallback, useEffect, useState } from 'react';
import { Controller, FieldError, FormProvider, useForm } from 'react-hook-form';
import { Box, FormControlLabel, MenuItem, Switch, TextField, Typography } from '@mui/material';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { CreateSurveyTemplateDto, LanguageEnum, SurveyTemplateDto } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { SurveyTemplateQuestionsArray } from './questions-array.component';
import { getLocalStorageDistrictId } from '../../../_core/utils';
import AddIcon from '@mui/icons-material/Add';
import { EyeIcon } from '../../../_core/components/icons';
import { useOpenSurveyModal } from '../../hooks/modal-forms/use-open-view-modal.hook';
import { CoreSelect } from '../../../_core/components/_ui/core-select';
import { AutocompleteTextField, CoreAutoComplete } from '../../../_core/components/_ui/core-autocomplete';
import { getObjectLanguage } from '../../../_core/components/languages-component';
import { CoreFormControl } from '../../../_core/components/_ui/core-form-control';

export type SurveyTemplateFormProps = {
	mode: boolean;
	defaultValues?: any;
	submitAction(values: CreateSurveyTemplateDto): Promise<void>;
};

export const SurveyTemplateForm: FC<SurveyTemplateFormProps> = (props) => {
	const [t] = useTranslation();
	const initiateValues = {
		districtId: getLocalStorageDistrictId(),
		title: '',
		questions: [],
		language: null,
	};

	const { submitAction, defaultValues = initiateValues, mode } = props;

	const resolver = classValidatorResolver(CreateSurveyTemplateDto);

	const methods = useForm({
		defaultValues,
		resolver,
		mode: 'all',
	});

	const {
		register,
		reset,
		handleSubmit,
		getValues,
		setValue,
		setError,
		control,
		watch,
		formState: { errors, touchedFields, isDirty, isLoading, isSubmitting, isSubmitted, isValid },
	} = methods;

	useEffect(() => {
		if (mode && defaultValues) {
			reset(defaultValues);
		}
	}, [defaultValues, reset, mode]);

	const validateCustomField = (value: string) => {
		if (isLanguageTemplate && !value) {
			setError('language', { type: 'custom', message: 'Language must start with "XYZ".' });
		}
		console.log(errors['language']);
	};

	const language = watch('language');
	const [isLanguageTemplate, setIsLanguageTemplate] = useState(!!getValues('language'));

	const disableSubmit = !isValid && (isDirty || isSubmitted);
	const loadingSubmit = isLoading || isSubmitting;

	const handleFormSubmit = useCallback(
		async (values: CreateSurveyTemplateDto) => {
			if (isLanguageTemplate && !values.language) {
				setError('language', { type: 'custom', message: 'Language is required' });
				return;
			}
			await submitAction(values);
		},
		[submitAction, isLanguageTemplate]
	);

	useEffect(() => {
		if (!isLanguageTemplate && language) {
			setValue('language', null);
		}
	}, [isLanguageTemplate]);

	const currentTitle = getValues('title');
	const currentQuestions = getValues('questions');
	const currentLanguage = getValues('language');

	const openSurveyModal = useOpenSurveyModal({
		modalTitle: currentTitle,
		questions: currentQuestions,
		language: isLanguageTemplate ? currentLanguage : null,
	});

	return (
		<FormProvider {...methods}>
			<Box
				component="form"
				onSubmit={handleSubmit(handleFormSubmit)}
				sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
			>
				<Box sx={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid lightgrey', paddingBottom: '16px' }}>
					<CoreTextField
						placeholder={t('survey-templates.forms.placeholders.enter-survey-name')}
						{...register('title')}
						error={!!errors['title']}
						helperText={errors['title'] ? (errors['title'] as FieldError).message : undefined}
						controlSx={{ width: '30%' }}
					/>

					<CoreAutoComplete
						{...register('language')}
						control={control}
						disabled={!isLanguageTemplate}
						ruleIsRequired={isLanguageTemplate}
						ruleName={t('engagements.forms.labels.language')}
						error={!!errors['language']}
						helperText={errors['language'] ? (errors['language'] as FieldError).message : undefined}
						//error={isLanguageTemplate && !language}
						//helperText={isLanguageTemplate && !language ? 'Language is required' : undefined}
						options={Object.values(LanguageEnum)}
						getOptionLabel={(option) => {
							const language = getObjectLanguage(option);
							return language ? language.name : '';
						}}
						selectOnFocus={true}
						isOptionEqualToValue={(option, value) => option === value}
						renderInput={(params) => (
							<AutocompleteTextField
								{...params}
								//error={isLanguageTemplate && !language}
								disabled={!isLanguageTemplate}
								onBlur={(e) => {
									validateCustomField(e.target.value);
								}}
								placeholder={t('engagements.forms.placeholders.options') ?? ''}
							/>
						)}
						renderOption={(props, option) => {
							const language = getObjectLanguage(option);
							return (
								<MenuItem {...props} key={language?.value}>
									{language?.label}
								</MenuItem>
							);
						}}
						controlSx={{
							minWidth: {
								xs: '180px',
								lg: '220px',
							},
							marginLeft: '32px',
						}}
					/>
					<FormControlLabel
						labelPlacement="top"
						control={
							<Switch
								checked={isLanguageTemplate}
								onChange={(e) => setIsLanguageTemplate(e.target.checked)}
								name="gilad"
							/>
						}
						label={
							<Box
								component="span"
								sx={{ color: (theme) => (isLanguageTemplate ? theme.colors.blue : theme.colors.gray) }}
							>
								Language
							</Box>
						}
					/>

					<Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
						<CoreButton variant="info-light" circle size="large" onClick={openSurveyModal}>
							<EyeIcon sx={{ fontSize: 30 }} />
						</CoreButton>
						<CoreButton
							type="submit"
							disabled={disableSubmit}
							loading={loadingSubmit}
							sx={{ marginLeft: '8px', fontSize: '16px' }}
						>
							<AddIcon />
							{t('survey-templates.forms.buttons.save-changes')}
						</CoreButton>
					</Box>
				</Box>
				<SurveyTemplateQuestionsArray />
			</Box>
		</FormProvider>
	);
};
