import React, { useState, useEffect, useCallback } from 'react';

export const usePaginatedData = <T>(useGetQuery: any, params: any, options?: any) => {
	const [page, setPage] = useState(1);
	const [itemsList, setItemsList] = useState<T[]>([]);

	const handleResetPage = useCallback(() => {
		setPage(1);
	}, []);

	const requestObject = useGetQuery(
		{
			page,
			...params,
		},
		options
	);

	const { data, refetch } = requestObject;

	useEffect(() => {
		refetch();
	}, [page, refetch]);

	useEffect(() => {
		if (data) {
			if (data.meta?.page === 1) {
				setItemsList(data.data);
			} else {
				setItemsList((prevItems) => [...prevItems, ...data.data]);
			}
		}
	}, [data]);

	const fetchMore = useCallback(() => {
		setPage(page + 1);
	}, [page]);

	return {
		itemsList,
		fetchMore,
		page,
		handleResetPage,
		requestObject,
		meta: data?.meta,
	};
};
